'use client';
import dynamic from 'next/dynamic';
import React, { lazy, PropsWithChildren, useRef, useState } from 'react';
import { useApolloLoadContext } from '@/src/components/apollo/apollo-load-context.tsx';

const ApolloWrapper = lazy(() =>
  import('./apollo-wrapper.tsx').then((module) => ({
    default: module.ApolloWrapper,
  })),
);

export function AsyncApolloWrapper({ children }: PropsWithChildren) {
  const { isLoaded } = useApolloLoadContext();
  const { current: apolloWasInitiallyLoaded } = useRef<boolean>(isLoaded);

  if (apolloWasInitiallyLoaded) {
    return children;
  } else {
    return <ApolloWrapper>{children}</ApolloWrapper>;
  }
}
