import { PropsWithChildren } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';

type Props = PropsWithChildren;
export function CorneredEdge({ children }: Props) {
  return (
    <Slot className="relative">
      <span className="from-secondary/80 to-secondary/20 absolute left-0 top-0 block h-full w-1 bg-gradient-to-r" />
      <Slottable>{children}</Slottable>
    </Slot>
  );
}
